var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-2xl"},[_vm._v(" Tarifas ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.services,"label":"Tipo","outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},on:{"change":_vm.fetchTaxes},model:{value:(_vm.filterServiceId),callback:function ($$v) {_vm.filterServiceId=$$v},expression:"filterServiceId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.customerLevels,"label":"Nível","outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},on:{"change":_vm.fetchTaxes},model:{value:(_vm.filterCustomerLevelId),callback:function ($$v) {_vm.filterCustomerLevelId=$$v},expression:"filterCustomerLevelId"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary"},on:{"click":_vm.fetchTaxes}},[_vm._v(" Pesquisar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEdit)+" ")])],1),_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.openFormToCreate}},[_vm._v(" Novo Registro "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTablePlus)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.rows,"item-key":"id","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","dark":"","text":"","title":"Editar","x-small":""},on:{"click":function($event){return _vm.openFormToEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileEdit))])],1)]}},{key:"item.serviceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveServiceName(item.serviceId))+" ")]}},{key:"item.customerLevelId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resolveCustomerLevelName(item.customerLevelId))+" ")]}},{key:"item.unitaryValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.$money(item.unitaryValue, 4))+" ")]}}],null,true)})],1)],1)],1),_c('validation-observer',{ref:"formTax",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchAction.apply(null, arguments)}}},[_c('dialog-form',{attrs:{"status":_vm.formDialogStatus,"is-update":_vm.formIsUpdate,"title":'Cadastro de Tarifa',"width":900},on:{"action":_vm.fetchAction,"remove":function($event){_vm.removeDialogStatus = true},"close":function($event){_vm.formDialogStatus = !_vm.formDialogStatus}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required","vid":"form.serviceId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"items":_vm.services,"label":"Tipo","dense":"","outlined":"","item-text":"name","item-value":"id"},model:{value:(_vm.form.serviceId),callback:function ($$v) {_vm.$set(_vm.form, "serviceId", $$v)},expression:"form.serviceId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nível","rules":"required","vid":"form.customerLevelId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"items":_vm.customerLevels,"label":"Nível","dense":"","outlined":"","item-text":"name","item-value":"id"},model:{value:(_vm.form.customerLevelId),callback:function ($$v) {_vm.$set(_vm.form, "customerLevelId", $$v)},expression:"form.customerLevelId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Valor (R$)","rules":"required","vid":"form.unitaryValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({
                precision: 4,
              }),expression:"{\n                precision: 4,\n              }"}],attrs:{"error-messages":errors,"hide-details":errors.length === 0,"outlined":"","dense":"","prefix":"R$","label":"Valor (R$)"},model:{value:(_vm.form.unitaryValue),callback:function ($$v) {_vm.$set(_vm.form, "unitaryValue", $$v)},expression:"form.unitaryValue"}})]}}])})],1)],1)],1)],1),_c('dialog-remove',{attrs:{"status":_vm.removeDialogStatus,"text-to-confirm":_vm.form.name},on:{"action":_vm.fetchActionRemove,"close":function($event){_vm.removeDialogStatus = !_vm.removeDialogStatus}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }