<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Tarifas
        </p>
        <v-card flat>
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-select
                  v-model="filterServiceId"
                  :items="services"
                  label="Tipo"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  @change="fetchTaxes"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-select
                  v-model="filterCustomerLevelId"
                  :items="customerLevels"
                  label="Nível"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  @change="fetchTaxes"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fetchTaxes"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiFileEdit }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="pl-4"
              outlined
              @click="openFormToCreate"
            >
              Novo Registro
              <v-icon
                right
                dark
              >
                {{ icons.mdiTablePlus }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.action`]="{item}">
              <v-btn
                color="success"
                dark
                text
                title="Editar"
                x-small
                @click="openFormToEdit(item.id)"
              >
                <v-icon>{{ icons.mdiFileEdit }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.serviceId`]="{item}">
              {{ resolveServiceName(item.serviceId) }}
            </template>
            <template #[`item.customerLevelId`]="{item}">
              {{ resolveCustomerLevelName(item.customerLevelId) }}
            </template>
            <template #[`item.unitaryValue`]="{item}">
              R$ {{ $money(item.unitaryValue, 4) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <validation-observer
      ref="formTax"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogStatus"
        :is-update="formIsUpdate"
        :title="'Cadastro de Tarifa'"
        :width="900"
        @action="fetchAction"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Tipo"
              rules="required"
              vid="form.serviceId"
            >
              <v-select
                v-model="form.serviceId"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :items="services"
                label="Tipo"
                dense
                outlined
                item-text="name"
                item-value="id"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nível"
              rules="required"
              vid="form.customerLevelId"
            >
              <v-select
                v-model="form.customerLevelId"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :items="customerLevels"
                label="Nível"
                dense
                outlined
                item-text="name"
                item-value="id"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Valor (R$)"
              rules="required"
              vid="form.unitaryValue"
            >
              <v-text-field
                v-model="form.unitaryValue"
                v-money="{
                  precision: 4,
                }"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                outlined
                dense
                prefix="R$"
                label="Valor (R$)"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <dialog-remove
      :status="removeDialogStatus"
      :text-to-confirm="form.name"
      @action="fetchActionRemove"
      @close="removeDialogStatus = !removeDialogStatus"
    >
    </dialog-remove>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiFileEdit, mdiMagnify, mdiTablePlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const services = ref([])
    const customerLevels = ref([])
    const filterName = ref(null)
    const filterServiceId = ref(null)
    const filterCustomerLevelId = ref(null)
    const formDialogStatus = ref(false)
    const removeDialogStatus = ref(false)
    const formIsUpdate = ref(false)
    const total = ref(null)
    const form = ref({
      id: ref(null),
      name: ref(''),
      serviceId: ref(null),
      quantity: ref(null),
      unitaryValue: ref(null),
    })

    return {
      total,
      filterName,
      filterServiceId,
      filterCustomerLevelId,
      form,
      formDialogStatus,
      removeDialogStatus,
      formIsUpdate,
      icons: {
        mdiFileEdit,
        mdiMagnify,
        mdiTablePlus,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Tipo', value: 'serviceId' },
        { text: 'Nível', value: 'customerLevelId' },
        { text: 'Valor (R$)', value: 'unitaryValue', align: 'right' },
      ],
      services,
      customerLevels,
    }
  },

  computed: {
    ...mapState('taxes', ['rows']),
  },

  async created() {
    this.services = await this.findServices()
    this.customerLevels = await this.findCustomerLevels()
    await this.fetchTaxes()
  },

  methods: {
    ...mapActions('services', { findServices: 'find' }),
    ...mapActions('customerLevels', { findCustomerLevels: 'find' }),
    ...mapActions('taxes', ['find', 'findById', 'create', 'update', 'remove']),

    resolveServiceName(serviceId) {
      const service = this.services.find(service => service.id === serviceId)

      if (service) {
        return service.name
      }

      return null
    },

    resolveCustomerLevelName(customerLevelId) {
      const customerLevels = this.customerLevels.find(customerLevel => customerLevel.id === customerLevelId)

      if (customerLevels) {
        return customerLevels.name
      }

      return null
    },

    async openFormToCreate() {
      this.formDialogStatus = true
      this.formIsUpdate = false
      this.form = {
        id: null,
        serviceId: null,
        customerLevelId: null,
        unitaryValue: null,
      }
    },

    async openFormToEdit(rowId) {
      const tax = await this.findById(rowId)

      if (tax) {
        await this.$refs.formTax.reset()
        this.formDialogStatus = true
        this.formIsUpdate = true
        this.form = {
          id: tax.id,
          serviceId: tax.serviceId,
          customerLevelId: tax.customerLevelId,
          unitaryValue: this.$money(tax.unitaryValue, 4),
        }
      }
    },

    async fetchTaxes() {
      await this.find({
        serviceId: this.filterServiceId,
        customerLevelId: this.filterCustomerLevelId,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        serviceId: Number(this.form.serviceId),
        customerLevelId: Number(this.form.customerLevelId),
        unitaryValue: this.$strip(this.form.unitaryValue),
      }

      if (await this.$refs.formTax.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        this.fetchTaxes()
        this.formDialogStatus = false
      }
    },

    async fetchActionRemove() {
      try {
        if (this.form.id) {
          await this.remove(this.form.id)
        }
      } finally {
        this.fetchTaxes()
        this.formDialogStatus = false
        this.removeDialogStatus = false
      }
    },
  },
}
</script>
